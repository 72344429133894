import React from 'react'
import './App.scss'
import Navbar from 'react-bootstrap/Navbar'
import {BrowserRouter as Router, Route, useParams} from 'react-router-dom'
import ProtocolScreen from './ProtocolScreen'
import logo from './assets/onlogist-logo.svg'
import Axios from 'axios';
import {configure} from 'axios-hooks';
import Container from 'react-bootstrap/Container';
import {Routes} from 'react-router'
import {NavDropdown} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import I18nContextProvider, {useI18n} from './i18n'

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

configure({ axios })

// @ts-ignore
const IS_WEBVIEW = window.ReactNativeWebView

const ProtocolDoneScreen = ({admin} : any) => {
    const {uuid} = useParams<{uuid:string}>()
    const {t} = useI18n()

    const pdfUrl = admin ? `${process.env.REACT_APP_API_BASE_URL}/admin/protocols/pdf?accessToken=${uuid}` : `${process.env.REACT_APP_API_BASE_URL}/protocols/${uuid}/pdf`

    return <Container className="p-3">
        <h1>{t('protocolSubmitted.head')}</h1>
        <p>{t('protocolSubmitted.head')}</p>

        {!IS_WEBVIEW && <>
            <div className="pdfHolder" style={{height: 'calc(100vh - 250px)'}}>
                <object data={pdfUrl + (admin ? '&' : '?') + 'inline=true'} width="100%" height="100%">
                    <a href={pdfUrl} download>{t('protocolSubmitted.downloadPdf')}</a>
                </object>
            </div>

            <hr/>
        </>}

        <a href={pdfUrl} className="btn btn-primary btn-lg">{t('downloadPdf')}</a>

    </Container>
}

const ProtocolNotReadyScreen = () => {
    const {t} = useI18n()
    return <Container className="p-3">
        <h1>{t('protocolNotActive.head')}</h1>
        <p>{t('protocolNotActive.copy')}</p>
    </Container>
}

const Navigation = () => {
    const {locale, setLocale, availableLocales, t} = useI18n()
    return (<Navbar bg="light">
        <Container>
            <Navbar.Brand>
                <img src={logo} alt="ONLOGIST.com" />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                {availableLocales?.length > 1 && <NavDropdown title={t('Locales.head')}>
                    {availableLocales.map(l =>
                        <NavDropdown.Item key={l} onClick={() => setLocale(l)}>{t('Locales.' + l)} {locale === l && <FontAwesomeIcon icon="check"></FontAwesomeIcon>}</NavDropdown.Item>
                    )}
                </NavDropdown>}
            </Navbar.Collapse>
        </Container>
    </Navbar>)
}
function App() {
    const noHeader = document.location.search?.indexOf("noHeader=") !== -1
    return (
            <Router>
                <I18nContextProvider>
                    {!noHeader && <Navigation />}
                    <Routes>
                        <Route path="/protocols/not-ready" element={<ProtocolNotReadyScreen />}/>
                        <Route path="/protocols/admin/:uuid" element={<ProtocolScreen admin={true} />}/>
                        <Route path="/protocols/admin/:uuid/done" element={<ProtocolDoneScreen admin={true} />}/>
                        <Route path="/protocols/:uuid/done" element={<ProtocolDoneScreen admin={false} />}/>
                        <Route path="/protocols/:uuid" element={<ProtocolScreen admin={false} />}/>
                    </Routes>
                </I18nContextProvider>
            </Router>
  )
}

export default App
