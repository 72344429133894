import * as React from 'react';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Polyglot from 'node-polyglot';
import {useSearchParams} from 'react-router-dom'
import {setDefaultLocale} from 'react-datepicker'
import {setLocale as setYupLocale} from 'yup'
import useAxios from 'axios-hooks'

export interface I18nContextProps {
    locale: string
    availableLocales: string[]
    setLocale: (locale: string) => void
    setAvailableLocales: (availableLocales: string[]) => void
    t: (phrase: string, options?: number | Polyglot.InterpolationOptions) => string
    getEffectiveLabel: (label?: string, key?: string) => string
    addLabels: (labels: {[key: string]: string}, prefix?: string) => void
}

const I18nContext = createContext<I18nContextProps>({} as I18nContextProps)

export const useI18n = () => useContext(I18nContext)

export default function I18nContextProvider({children}: any) {
    const [searchParams, setSearchParams] = useSearchParams()

    const [i18n, setI18n] = useState<Polyglot>()
    const [locale, setLocale] = useState<string>(searchParams?.get('lang') || 'de')
    const [availableLocales, setAvailableLocales] = useState<string[]>([])

    const [{ data }] = useAxios('/i18n')

    useEffect(() => {
        if (data) {
            setI18n(new Polyglot({
                phrases: data
            }))
            setAvailableLocales(Object.keys(data))
        }
    }, [data])

    const t = useCallback((phrase, options?) => {
        return i18n?.t(`${locale}.${phrase}`, options) || ''
    }, [i18n, locale])

    const getEffectiveLabel = useCallback((label?: string, key?: string) => {
            return label ? t(label, {_: label}) : t(key, {_: ''})
    }, [t])

    useEffect(() => {
        const getMessage = ({key, params}: {key: string, params: any}) => ({
            key,
            params: {
                ...params,
                field: params.path ? t('Fields.' + params.path, {_: ''}) : ''
            }
        })

        setYupLocale({
            mixed: {
                default: ({path}) => getMessage({key: 'validationError.default', params: {path}}),
                required: ({path}) => getMessage({key: 'validationError.required', params: {path}}),
                oneOf: ({path, values}) => getMessage({key: 'validationError.oneOf', params: {path, values}}),
                notOneOf: ({path, values}) => getMessage({key: 'validationError.notOneOf', params: {path, values}}),
            },
            string: {
                length: ({path, length}) => getMessage({key: 'validationError.length', params: {path, length}}),
                min: ({path, min}) => getMessage({key: 'validationError.min', params: {path, min}}),
                max: ({path, max}) => getMessage({key: 'validationError.max', params: {path, max}}),
                matches: ({path, regex}) => getMessage({key: 'validationError.matches', params: {path, regex}}),
                email: ({path}) => getMessage({key: 'validationError.email', params: {path}}),
                url: ({path}) => getMessage({key: 'validationError.url', params: {path}}),
                trim: ({path}) => getMessage({key: 'validationError.trim', params: {path}}),
                lowercase: ({path}) => getMessage({key: 'validationError.lowercase', params: {path}}),
                uppercase: ({path}) => getMessage({key: 'validationError.uppercase', params: {path}}),
            },
            number: {
                min: ({path, min}) => getMessage({key: 'validationError.numMin', params: {path, min}}),
                max: ({path, max}) => getMessage({key: 'validationError.numMax', params: {path, max}}),
                lessThan: ({path, less}) => getMessage({key: 'validationError.lessThan', params: {path, less}}),
                moreThan: ({path, more}) => getMessage({key: 'validationError.moreThan', params: {path, more}}),
                positive: ({path}) => getMessage({key: 'validationError.positive', params: {path}}),
                negative: ({path}) => getMessage({key: 'validationError.negative', params: {path}}),
                integer: ({path}) => getMessage({key: 'validationError.integer', params: {path}}),
            }
        })
    }, [t])

    useEffect(() => {
        setSearchParams({lang: locale})
        setDefaultLocale(locale)
    }, [locale, setSearchParams])

    useEffect(() => {
        if (availableLocales.length && !availableLocales.includes(locale)) {
            setLocale(availableLocales[0])
        }
    }, [availableLocales, locale])

    const addLabels = useCallback((labels: {[key: string]: string}, prefix?: string) => {
        if (prefix) {
            i18n?.extend({[prefix]: labels}, locale)
        } else {
            i18n?.extend(labels, locale)
        }
    }, [i18n, locale])

    const context: I18nContextProps = useMemo(() => ({
        locale,
        setLocale,
        availableLocales,
        setAvailableLocales,
        t,
        getEffectiveLabel,
        addLabels,
    }), [addLabels, availableLocales, locale, t, getEffectiveLabel])

    if (i18n) {
        return (
            <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
        )
    } else {
        return null
    }
}
